import React from 'react'
import { graphql } from 'gatsby'

import styled, { css } from 'styled-components'
import { Media } from 'src/styles/mixins'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import Head from 'src/components/molecules/Head'
import Wrapper from 'src/components/organisms/Wrapper'
import Archive from 'src/components/molecules/Archive'

const Author = ({ data }) => {
  const {
    id,
    name,
    slug,
    image,
    description,
    posts,
    twitter,
    instagram,
    line,
    facebook,
  } = data.contentfulAuthors
  const settings = data.contentfulSettings

  return (
    <Layout>
      <SEO />

      <Head
        title={ name }
        slug={ slug }
        image={ image }
        description={ description.childMarkdownRemark.html }
        twitter={ twitter }
        instagram={ instagram }
        line={ line }
        facebook={ facebook } />

      <Wrapper settings={ settings }>
        <Archive type="main" posts={ posts } />
      </Wrapper>
    </Layout>
  )
}

export default Author

export const query = graphql`
  query($slug: String!) {
    contentfulAuthors(slug: { eq: $slug }) {
      id
      name
      slug
      image {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      posts {
        id
        title
        excerpt {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        slug
        tags {
          id
          slug
          title
          group
        }
        image {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
      }
      twitter
      instagram
      line
      facebook
    }

    contentfulSettings(node_locale: {eq: "ja-JP"}) {
      siteName
      siteCaption
      siteDescription
      twitter
      facebook
      instagram
      line
    }
  }
`